
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { ShareModule } from '../../store'

@Component({
  name: 'ShareAppbarIcePanelLink'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentShareLink.versionId)!
  }

  get exploreShareLink () {
    return this.currentLandscape.organizationId === '3Cu66d23v2AGefxeWTQ2'
  }

  getStartedLink () {
    window.open('http://u.icepanel.io/926f698d', '_blank')?.focus()

    analytics.shareLinkGetStartedLink.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      shareLinkShortId: this.$params.shortId
    })
  }

  designedInIcePanelLink () {
    window.open('http://u.icepanel.io/e08436cb', '_blank')?.focus()

    analytics.shareLinkDesignedInIcePanelLink.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      shareLinkShortId: this.$params.shortId
    })
  }
}
