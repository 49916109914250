import * as fasArrowsAltH from '@fortawesome/free-solid-svg-icons/faArrowsAltH'
import * as fasBolt from '@fortawesome/free-solid-svg-icons/faBolt'
import * as fasCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as fasCaretUp from '@fortawesome/free-solid-svg-icons/faCaretUp'
import * as fasEnvelope from '@fortawesome/free-solid-svg-icons/faEnvelope'
import * as fasEye from '@fortawesome/free-solid-svg-icons/faEye'
import * as fasEyeSlash from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import * as fasFileCode from '@fortawesome/free-solid-svg-icons/faFileCode'
import * as fasFileCsv from '@fortawesome/free-solid-svg-icons/faFileCsv'
import * as fasProjectDiagram from '@fortawesome/free-solid-svg-icons/faProjectDiagram'
import * as fasQuestionCircle from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import * as fasSitemap from '@fortawesome/free-solid-svg-icons/faSitemap'

export default [
  fasArrowsAltH.definition,
  fasBolt.definition,
  fasCaretDown.definition,
  fasCaretUp.definition,
  fasEnvelope.definition,
  fasEye.definition,
  fasEyeSlash.definition,
  fasFileCode.definition,
  fasFileCsv.definition,
  fasProjectDiagram.definition,
  fasQuestionCircle.definition,
  fasSitemap.definition
] as const
