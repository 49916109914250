import * as analytics from '@icepanel/app-analytics'
import { ShareLinkFrameLocation } from '@icepanel/platform-api-client'

export const shareLinkShortId: analytics.Property<string> = {
  displayName: 'Share link short ID',
  name: 'share_link_short_id',
  required: true,
  type: 'string'
}
export const shareLinkInFrame: analytics.Property<boolean> = {
  displayName: 'Share link in iFrame',
  name: 'share_link_in_frame',
  required: true,
  type: 'boolean'
}
export const shareLinkFrameLocation: analytics.Property<ShareLinkFrameLocation | 'other' | undefined> = {
  displayName: 'Share link iFrame location',
  examples: ['confluence', 'miro', 'notion', 'powerpoint', 'sharepoint', 'other'],
  name: 'share_link_frame_location',
  required: false,
  type: 'string'
}
export const shareLinkProtected: analytics.Property<boolean> = {
  displayName: 'Share link protected',
  name: 'share_link_protected',
  required: true,
  type: 'boolean'
}
export const shareLinkAuthProvider: analytics.Property<string> = {
  displayName: 'Share link auth provider',
  name: 'share_link_auth_provider',
  required: true,
  type: 'string'
}

/**
 * Hidden
 */

export const shareLinkMode: analytics.Property<string | undefined> = {
  displayName: 'Share link mode',
  hidden: true,
  name: 'share_link_mode',
  required: false,
  type: 'string'
}
export const shareLinkModel: analytics.Property<string | undefined> = {
  displayName: 'Share link model',
  hidden: true,
  name: 'share_link_model',
  required: false,
  type: 'string'
}
export const shareLinkPerspective: analytics.Property<string | undefined> = {
  displayName: 'Share link perspective',
  hidden: true,
  name: 'share_link_perspective',
  required: false,
  type: 'string'
}
export const shareLinkDiagram: analytics.Property<string | undefined> = {
  displayName: 'Share link diagram',
  hidden: true,
  name: 'share_link_diagram',
  required: false,
  type: 'string'
}
export const shareLinkX: analytics.Property<number | undefined> = {
  displayName: 'Share link x',
  hidden: true,
  name: 'share_link_x',
  required: false,
  type: 'number'
}
export const shareLinkY: analytics.Property<number | undefined> = {
  displayName: 'Share link y',
  hidden: true,
  name: 'share_link_y',
  required: false,
  type: 'number'
}
export const shareLinkScale: analytics.Property<number | undefined> = {
  displayName: 'Share link scale',
  hidden: true,
  name: 'share_link_scale',
  required: false,
  type: 'number'
}

/**
 * Hidden
 */

export const shareExportJsonDescriptions: analytics.Property<boolean> = {
  displayName: 'Share export JSON descriptions',
  hidden: true,
  name: 'share_export_json_descriptions',
  required: true,
  type: 'boolean'
}
export const shareExportCsvDescriptions: analytics.Property<boolean> = {
  displayName: 'Share export CSV descriptions',
  hidden: true,
  name: 'share_export_csv_descriptions',
  required: true,
  type: 'boolean'
}
