
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { ShareModule } from '../../store'

@Component({
  components: {
    Dialog
  },
  name: 'ShareBlankExportDialog'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly dialog!: Dialog

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.versionId === this.currentVersion.id)!
  }

  get currentVersionModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentVersion.modelHandleId)
  }

  open () {
    this.dialog.open()
  }

  close () {
    this.dialog.close()
  }

  opened () {
    analytics.shareBlankDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      versionLatest: this.currentVersion.tags.includes('latest'),
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })
  }
}
