
import { FormController, IVForm, validationRules } from '@icepanel/app-form'
import { ShareLinkFrameLocation, ShareLinkOptionsObjectTab, ShareLinkOptionsRequired } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { SupportModule } from '@/modules/support/store'
import { TagModule } from '@/modules/tag/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { ShareModule } from '../../store'

@Component({
  name: 'ShareDialogEmbed'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  supportModule = getModule(SupportModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly passwordForm!: IVForm
  @Ref() readonly passwordInput!: HTMLElement

  loading = true
  loadingShareToggle = false
  loadingProtectedToggle = false
  loadingGenerateLink = false
  loadingGenerateOptions = false

  preventNavigation = false
  showPassword = false
  editingPassword = false

  copy: string | null = null
  copyTimer?: number

  embedLocation: ShareLinkFrameLocation | 'other' | null = null

  formController = new FormController({
    initialModel: {
      password: ''
    },
    validationRules: {
      password: [
        ...validationRules.exists,
        ...validationRules.password
      ]
    }
  })

  requestToolFormController = new FormController({
    initialModel: {
      tool: ''
    },
    validationRules: {
      tool: validationRules.exists
    }
  })

  get curretDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion.landscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.versionId === this.currentVersion.id)!
  }

  get currentShareLinkOptions () {
    return this.shareModule.shareLinkOptions?.shareLinkId === this.currentShareLink.id ? this.shareModule.shareLinkOptions : undefined
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.curretDiagramHandleId)
  }

  get currentVersionModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentVersion.modelHandleId)
  }

  get currentVersionModelDomain () {
    return this.currentVersionModel ? this.domainModule.domains[this.currentVersionModel.domainId] : undefined
  }

  get shareLinksEnabled () {
    return this.currentOrganization.shareLinkAuthDomains === null || this.currentOrganization.shareLinkAuthDomains.length !== 0
  }

  get shareLinkSSOEnabled () {
    return this.currentOrganization.shareLinkAuthDomains !== null && !!this.currentOrganization.shareLinkAuthDomains.length
  }

  get shareLinkDraft () {
    return this.currentDiagram?.status === 'draft'
  }

  get sharingDocsUrl () {
    return 'https://docs.icepanel.io/features/sharing'
  }

  get latestVersion () {
    return this.currentVersion.tags.includes('latest')
  }

  get shareLinkOptions () {
    const options: ShareLinkOptionsRequired = {
      connectionIds: null,
      diagramId: this.$queryValue('diagram'),
      domainId: this.$queryValue('domain'),
      drawer: this.$queryValue('drawer') as 'expanded' | 'collapsed' | null,
      expandedIds: null,
      filterIds: null,
      filterIdsExcluded: null,
      filterIdsIncluded: null,
      flowId: this.$queryValue('flow'),
      flowParentIds: null,
      flowPathIds: null,
      flowStepId: this.$queryValue('flow_step'),
      mode: 'diagrams',
      modelId: this.$queryValue('model'),
      objectIds: null,
      objectIdsFocused: null,
      objectTab: this.$queryValue('object_tab') as ShareLinkOptionsObjectTab,
      overlayGroupId: this.$queryValue('overlay_group'),
      overlayIdsFocused: null,
      overlayIdsHidden: null,
      overlayIdsPinned: null,
      overlayTab: null,
      preventNavigation: false,
      search: this.$queryValue('search'),
      x1: null,
      x2: null,
      y: null,
      y1: null,
      y2: null
    }

    const overlayTab = this.$queryValue('overlay_tab')
    if (overlayTab === 'tags') {
      options.overlayTab = 'tags'
    } else if (overlayTab === 'technology') {
      options.overlayTab = 'technology'
    } else if (overlayTab === 'status') {
      options.overlayTab = 'status'
    } else {
      options.overlayTab = 'tags'
      options.overlayGroupId = null
      options.overlayIdsFocused = null
      options.overlayIdsHidden = null
      options.overlayIdsPinned = null
    }

    if (this.$routeName === 'overview') {
      options.mode = 'overview'
    } else if (this.$routeName === 'diagrams') {
      options.mode = 'diagrams'
    } else if (this.$routeName === 'model-objects') {
      options.mode = 'model-objects'
    } else if (this.$routeName === 'flows') {
      options.mode = 'flows'
    } else if (this.$routeName === 'model-dependencies') {
      options.mode = 'dependencies'
    } else if (options.flowId) {
      options.mode = 'flow'
      options.preventNavigation = this.preventNavigation
    } else if (options.diagramId) {
      options.mode = 'diagram'
      options.preventNavigation = this.preventNavigation
    }

    const y = this.$queryValue('y')
    const yNumber = y ? parseFloat(y) : null
    options.y = yNumber ? Math.round(yNumber * 10) / 10 : null

    const x1 = this.$queryValue('x1')
    const x1Number = x1 ? parseFloat(x1) : null
    options.x1 = x1Number ? Math.round(x1Number * 10) / 10 : null

    const y1 = this.$queryValue('y1')
    const y1Number = y1 ? parseFloat(y1) : null
    options.y1 = y1Number ? Math.round(y1Number * 10) / 10 : null

    const x2 = this.$queryValue('x2')
    const x2Number = x2 ? parseFloat(x2) : null
    options.x2 = x2Number ? Math.round(x2Number * 10) / 10 : null

    const y2 = this.$queryValue('y2')
    const y2Number = y2 ? parseFloat(y2) : null
    options.y2 = y2Number ? Math.round(y2Number * 10) / 10 : null

    const objects = this.$queryArray('object')
    if (objects.length) {
      options.objectIds = objects
    }

    const objectsFocused = this.$queryArray('object_focus')
    if (objectsFocused.length) {
      options.objectIdsFocused = objectsFocused
    }

    const connections = this.$queryArray('connection')
    if (connections.length) {
      options.connectionIds = connections
    }

    const expanded = this.$queryArray('expanded')
    if (expanded.length) {
      options.expandedIds = expanded
    }

    const overlayPin = this.$queryArray('overlay_pin')
    if (overlayPin.length) {
      options.overlayIdsPinned = overlayPin
    }

    const overlayHide = this.$queryArray('overlay_hide')
    if (overlayHide.length) {
      options.overlayIdsHidden = overlayHide
    }

    const overlayFocus = this.$queryArray('overlay_focus')
    if (overlayFocus.length) {
      options.overlayIdsFocused = overlayFocus
    }

    const filter = this.$queryArray('filter')
    if (filter.length) {
      options.filterIds = filter
    }

    const filterInclude = this.$queryArray('filter_include')
    if (filterInclude.length) {
      options.filterIdsIncluded = filterInclude
    }

    const filterExclude = this.$queryArray('filter_exclude')
    if (filterExclude.length) {
      options.filterIdsExcluded = filterExclude
    }

    const flowPaths = this.$queryArray('flow_path')
    if (flowPaths.length) {
      options.flowPathIds = flowPaths
    }

    const flowParents = this.$queryArray('flow_parent')
    if (flowParents.length) {
      options.flowParentIds = flowParents
    }

    return options
  }

  get shareLinkUrlPrefix () {
    const url = new URL(env.SHARE_URL)
    return `${url.host}${url.pathname}`
  }

  get currentShareLinkPassword () {
    return this.currentShareLink?.password
  }

  get currentShareLinkUrl () {
    const urlPrefix = this.currentShareLink && this.shareModule.shareLinkUrls[this.currentShareLink.id]
    if (urlPrefix && this.currentShareLinkOptions) {
      return `${urlPrefix}/${this.currentShareLinkOptions.shortId}`
    } else {
      return ''
    }
  }

  get currentShareLinkUrlWithFrame () {
    if (this.embedLocation && this.embedLocation !== 'other') {
      return `${this.currentShareLinkUrl}?frame=${this.embedLocation}`
    } else if (this.currentShareLinkUrl) {
      return this.currentShareLinkUrl
    } else {
      return ''
    }
  }

  get currentShareLinkFrame () {
    return `<iframe src="${this.currentShareLinkUrlWithFrame}" height="800" width="1200" title="${this.currentOrganization.name} - ${this.currentLandscape.name}" style="border-radius: 16px; border: none"></iframe>`
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get organizationLimitShareLinkProtected () {
    return this.currentOrganizationLimits.shareLinkProtected
  }

  get currentEmbedLocation () {
    return this.embedLocations.find(o => o.id === this.embedLocation)
  }

  get title () {
    if (!this.shareLinksEnabled) {
      return 'Share links disabled'
    } else if (!this.latestVersion && this.currentVersionModel && this.currentVersionModel.type === 'root' && this.currentVersionModelDomain && this.currentOrganizationLimits.versionObjects && this.currentOrganizationLimits.domains > 1) {
      return `Share link for ${this.currentVersionModelDomain.name}`
    } else if (!this.latestVersion && this.currentVersionModel && this.currentVersionModel.type !== 'root' && this.currentOrganizationLimits.versionObjects) {
      return `Share link for ${this.currentVersionModel.name || `${this.currentVersionModel.type}`}`
    } else {
      return 'Share link for '
    }
  }

  get subtitle () {
    if (!this.shareLinksEnabled) {
      return ''
    } else if (this.latestVersion) {
      return 'latest version'
    } else {
      return `version ${this.currentVersion.name}`
    }
  }

  get embedLocations (): { id: ShareLinkFrameLocation, name: string, image: string, aspectRatio: string, docsUrl: string }[] {
    return [
      {
        aspectRatio: '1.0748898678',
        docsUrl: `${this.sharingDocsUrl}#embed-in-confluence`,
        id: 'confluence',
        image: require('../../assets/embed/confluence.png'),
        name: 'Confluence Cloud'
      },
      {
        aspectRatio: '0.9821428571',
        docsUrl: `${this.sharingDocsUrl}#embed-in-notion`,
        id: 'notion',
        image: require('../../assets/embed/notion.png'),
        name: 'Notion'
      },
      {
        aspectRatio: '1',
        docsUrl: `${this.sharingDocsUrl}#embed-in-miro`,
        id: 'miro',
        image: require('../../assets/embed/miro.png'),
        name: 'Miro'
      },
      {
        aspectRatio: '1.0181818182',
        docsUrl: `${this.sharingDocsUrl}#embed-in-sharepoint`,
        id: 'sharepoint',
        image: require('../../assets/embed/sharepoint.png'),
        name: 'SharePoint'
      },
      {
        aspectRatio: '1.0892857143',
        docsUrl: `${this.sharingDocsUrl}#embed-in-powerpoint---office-365`,
        id: 'powerpoint',
        image: require('../../assets/embed/powerpoint.png'),
        name: 'PowerPoint (Office 365)'
      }
    ]
  }

  get formIcon () {
    if (this.formController.submitting) {
      return undefined
    } else if (this.formController.disabled) {
      return '$fas-pencil-alt'
    } else {
      return '$fas-check'
    }
  }

  @Watch('embedLocation')
  onEmbedLocationChanged (embedLocation: ShareLinkFrameLocation | null | 'other') {
    analytics.shareEmbedScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentOrganization.id],
      shareLinkFrameLocation: embedLocation || undefined,
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })
  }

  @Watch('shareLinkOptions')
  onShareLinkOptionsChanged () {
    this.generateOptions()
  }

  @Watch('currentShareLinkPassword')
  onCurrentShareLinkPasswordChanged (currentShareLinkPassword?: string) {
    this.formController.setInitialModel({
      password: currentShareLinkPassword || ''
    })
  }

  created () {
    this.formController.submitHandler = async model => {
      const shareLink = await this.shareModule.shareLinkUpdate({
        landscapeId: this.currentLandscape.id,
        props: {
          password: model.password
        },
        versionId: this.currentVersion.id
      })

      analytics.shareLinkUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkProtected: shareLink.protected,
        versionLatest: this.currentVersion.tags.includes('latest'),
        versionModelId: this.currentVersionModel?.id || null,
        versionModelType: this.currentVersionModel?.type || null
      })
    }

    this.requestToolFormController.submitHandler = async model => {
      if (this.userModule.user) {
        await this.supportModule.supportMessageCreate({
          body: {
            toolName: model.tool,
            type: 'embed-tool-request'
          },
          email: this.userModule.user.email,
          landscapeId: this.currentLandscape?.id,
          name: this.userModule.user.name,
          organizationId: this.currentOrganization?.id,
          url: window.location.href,
          userId: this.userModule.user.id
        })
      }
    }
  }

  async mounted () {
    this.formController.disabled = true
    this.formController.on('success', () => {
      this.formController.disabled = true
    })
    this.formController.on('error', message => {
      this.alertModule.pushAlert({
        color: 'error',
        message
      })
    })

    analytics.shareEmbedScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentOrganization.id],
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })

    try {
      const [shareLink] = await Promise.all([
        this.shareModule.shareLinkFind({
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        }),
        this.shareModule.shareLinkOptionsUpsert({
          landscapeId: this.currentLandscape.id,
          props: this.shareLinkOptions,
          versionId: this.currentVersion.id
        })
      ])
      this.formController.setInitialModel({
        password: shareLink?.shareLink.password || ''
      })
      this.formController.resetModel()
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loading = false
    }
  }

  destroyed () {
    clearTimeout(this.copyTimer)
    this.copyTimer = undefined
  }

  formSubmit () {
    if (!this.formController.submitting) {
      if (this.formController.disabled) {
        this.formController.disabled = false
        this.passwordInput.focus()
        this.formController.model.password = ''
      } else {
        this.formController.submit(this.passwordForm)
      }
    }
  }

  async shareLinkToggle () {
    if (this.loadingShareToggle) {
      return
    }
    this.loadingShareToggle = true
    try {
      if (this.currentShareLink) {
        const shareLink = this.currentShareLink

        await this.shareModule.shareLinkDelete({
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        })
        this.shareModule.setShareLinkOptions(null)

        analytics.shareLinkDelete.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          shareLinkProtected: shareLink.protected,
          versionLatest: this.currentVersion.tags.includes('latest'),
          versionModelId: this.currentVersionModel?.id || null,
          versionModelType: this.currentVersionModel?.type || null
        })

        this.embedLocation = null
      } else {
        const shareLink = await this.shareModule.shareLinkCreate({
          landscapeId: this.currentLandscape.id,
          props: {
            protected: false
          },
          versionId: this.currentVersion.id
        })
        await this.shareModule.shareLinkOptionsUpsert({
          landscapeId: this.currentLandscape.id,
          props: this.shareLinkOptions,
          versionId: this.currentVersion.id
        })

        this.formController.setInitialModel({
          password: shareLink.password || ''
        })
        this.formController.resetModel()

        analytics.shareLinkCreate.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          shareLinkProtected: false,
          versionLatest: this.currentVersion.tags.includes('latest'),
          versionModelId: this.currentVersionModel?.id || null,
          versionModelType: this.currentVersionModel?.type || null
        })
      }
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loadingShareToggle = false
    }
  }

  async generateLink () {
    if (this.loadingGenerateLink || !this.currentShareLink) {
      return
    }
    this.loadingGenerateLink = true
    try {
      await this.shareModule.shareLinkUpdate({
        landscapeId: this.currentLandscape.id,
        props: {},
        resetShortId: true,
        versionId: this.currentVersion.id
      })
      await this.shareModule.shareLinkOptionsUpsert({
        landscapeId: this.currentLandscape.id,
        props: this.shareLinkOptions,
        versionId: this.currentVersion.id
      })
      await this.shareModule.shareLinkFind({
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    } finally {
      this.loadingGenerateLink = false
    }
  }

  async generateOptions () {
    if (this.loadingGenerateOptions || !this.currentShareLink) {
      return
    }
    this.loadingGenerateOptions = true
    try {
      await this.shareModule.shareLinkOptionsUpsert({
        landscapeId: this.currentLandscape.id,
        props: this.shareLinkOptions,
        versionId: this.currentVersion.id
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    } finally {
      this.loadingGenerateOptions = false
    }
  }

  async shareProtectedToggle () {
    if (this.loadingProtectedToggle || !this.currentShareLink) {
      return
    }
    this.loadingProtectedToggle = true
    try {
      const shareLink = await this.shareModule.shareLinkUpdate({
        landscapeId: this.currentLandscape.id,
        props: {
          protected: !this.currentShareLink.protected
        },
        versionId: this.currentVersion.id
      })

      analytics.shareLinkUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkProtected: shareLink.protected,
        versionLatest: this.currentVersion.tags.includes('latest'),
        versionModelId: this.currentVersionModel?.id || null,
        versionModelType: this.currentVersionModel?.type || null
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    } finally {
      this.loadingProtectedToggle = false
    }
  }

  async copyText (id: string, text: string) {
    await navigator.clipboard.writeText(text)

    analytics.shareEmbedCopyClipboard.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentOrganization.id],
      shareLinkFrameLocation: this.embedLocation || undefined,
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })

    this.copy = id
    clearTimeout(this.copyTimer)
    this.copyTimer = window.setTimeout(() => {
      this.copy = null
    }, 2000)
  }
}
