
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  name: 'ShareAppbarTitle'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentOrganizationBrand () {
    return this.currentLandscape?.organizationId ? this.organizationModule.organizationBrands[this.currentLandscape.organizationId] : undefined
  }

  get currentVersionLatest () {
    return this.currentVersion?.tags.includes('latest')
  }

  get title () {
    return this.currentOrganizationBrand?.name
  }

  get subtitle () {
    return this.currentLandscape?.name
  }

  get subtitleCaption () {
    if (this.currentVersionLatest) {
      return undefined
    } else if (this.currentVersion) {
      const verisonModel = Object.values(this.modelModule.objects).find(o => o.handleId === this.currentVersion?.modelHandleId)
      if (verisonModel?.type !== 'root') {
        const versionModelName = verisonModel ? verisonModel.name || `${verisonModel.type.slice(0, 1).toUpperCase()}${verisonModel.type.slice(1)}` : ''
        return ` - ${versionModelName} version ${this.currentVersion.name}`
      } else {
        return ` - Version ${this.currentVersion.name}`
      }
    }
  }
}
