
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  name: 'ShareAppbarLogo'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentShareLink.versionId)!
  }

  get exploreShareLink () {
    return this.currentLandscape.organizationId === '3Cu66d23v2AGefxeWTQ2'
  }

  click () {
    if (this.shareModule.shareLinkInFrame) {
      window.open(window.location.href, '_blank')?.focus()
    } else if (this.exploreShareLink) {
      window.open('http://u.icepanel.io/4e5f7b3a', '_blank')?.focus()

      analytics.shareLinkIcePanelLink.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkShortId: this.$params.shortId
      })
    } else {
      window.open('http://u.icepanel.io/723f1369', '_blank')?.focus()

      analytics.shareLinkIcePanelLink.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkShortId: this.$params.shortId
      })
    }
  }
}
