
import { PermissionType, Theme } from '@icepanel/platform-api-client'
import * as FileSaver from 'file-saver'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { TagModule } from '@/modules/tag/store'
import { TeamModule } from '@/modules/team/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { ShareModule } from '../../store'
import BlankExportDialog from './blank-export-dialog.vue'

@Component({
  components: {
    BlankExportDialog
  },
  name: 'ShareDialogExport'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly blankExportDialog!: BlankExportDialog

  @Prop() readonly permission!: PermissionType

  exportUrls: Record<Theme, string | null> = {
    dark: null,
    light: null
  }

  exportingPdf = false
  exportedPdf = false

  exportingJson = false
  exportingCsvObjects = false
  exportingCsvConnections = false

  get shortId () {
    return this.$params.shortId
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get toolbar () {
    return this.$queryValue('toolbar')
  }

  get currentFlowPathIds () {
    return this.$queryArray('flow_path')
  }

  get currentFlowParentIds () {
    return this.$queryArray('flow_parent')
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get overlayIdsHidden () {
    return this.$queryArray('overlay_hide')
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion.landscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.versionId === this.currentVersion.id)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentFlow () {
    return Object.values(this.flowModule.flows).find(o => o.diagramId === this.currentDiagram?.id && o.handleId === this.currentFlowHandleId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContentEmpty () {
    return !Object.keys(this.currentDiagramContent?.objects || {}).length
  }

  get currentVersionModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentVersion.modelHandleId)
  }

  get currentVersionModelDomain () {
    return this.currentVersionModel ? this.domainModule.domains[this.currentVersionModel.domainId] : undefined
  }

  get currentTagGroup () {
    return Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId)
  }

  get title () {
    if (!this.latestVersion && this.currentVersionModel && this.currentVersionModel.type === 'root' && this.currentVersionModelDomain && this.currentOrganizationLimits.versionObjects && this.currentOrganizationLimits.domains > 1) {
      return `Export ${this.currentVersionModelDomain.name}`
    } else if (!this.latestVersion && this.currentVersionModel && this.currentVersionModel.type !== 'root' && this.currentOrganizationLimits.versionObjects) {
      return `Export ${this.currentVersionModel.name || `${this.currentVersionModel.type}`}`
    } else {
      return 'Export '
    }
  }

  get subtitle () {
    if (this.latestVersion) {
      return 'latest version'
    } else {
      return `version ${this.currentVersion.name}`
    }
  }

  get latestVersion () {
    return this.currentVersion.tags.includes('latest')
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get showShare () {
    return this.currentLandscapePermission && this.currentLandscapePermission !== 'read' && (this.currentOrganizationLimits.shareLinkVersions || this.currentVersion?.tags.includes('latest'))
  }

  mounted () {
    analytics.shareExportScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })

    if (!this.currentDiagramContentEmpty) {
      this.exportPng('light')
      this.exportPng('dark')
    }
  }

  async exportPng (theme: Theme) {
    if (this.exportUrls[theme] || !this.currentDiagram) {
      return
    }

    const filename = `${this.currentLandscape.name} - ${this.currentDiagram.name} (${this.currentVersion.name}).png`
    const url = await this.diagramModule.diagramExportPng({
      diagramId: this.currentDiagram.id,
      landscapeId: this.currentLandscape.id,
      options: {
        filename,
        flowId: this.currentFlow?.id,
        flowParentIds: this.currentFlowParentIds,
        flowPathIds: this.currentFlowPathIds,
        overlayGroupId: this.overlayGroupId,
        overlayIdsFocused: this.overlayIdsFocused,
        overlayIdsHidden: this.overlayIdsHidden,
        overlayIdsPinned: this.overlayIdsPinned,
        theme
      },
      versionId: this.currentVersion.id
    })

    let exists = false
    let attempts = 0
    do {
      await new Promise(resolve => setTimeout(resolve, 1000))

      const req = await fetch(url)
      exists = req.status === 200
      attempts++

      if (attempts > 60) {
        return
      }
    } while (!exists)

    this.exportUrls[theme] = url
  }

  shareExportPngEvent (theme: Theme) {
    if (!this.currentDiagram) {
      return
    }

    analytics.shareExportPng.track(this, {
      diagramFlowId: this.currentFlow?.id,
      diagramTheme: theme,
      diagramType: this.currentDiagram.type,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      shareLinkShortId: this.shortId,
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })
  }

  async exportPdf () {
    const user = this.userModule.user
    try {
      if (this.exportingPdf || !user) {
        return
      }
      this.exportingPdf = true

      if (Object.keys(this.modelModule.objects).length) {
        const filename = `${this.currentLandscape.name} (${this.currentVersion.name}).pdf`
        await this.editorModule.landscapeExportPdf({
          email: user.email,
          filename,
          landscapeId: this.currentLandscape.id,
          name: user.name,
          organizationId: this.currentLandscape.organizationId,
          versionId: this.currentVersion.id
        })

        analytics.shareExportPdf.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          shareLinkShortId: this.shortId,
          versionLatest: this.latestVersion,
          versionModelId: this.currentVersionModel?.id || null,
          versionModelType: this.currentVersionModel?.type || null
        })

        this.exportedPdf = true

        this.alertModule.pushAlert({
          color: 'success',
          message: `You'll receive your export to ${user.email}, this shouldn't take more than 10 mins`
        })
      } else {
        this.blankExportDialog.open()
      }
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'An error occured during export, please contact support'
      })
      throw err
    } finally {
      this.exportingPdf = false
    }
  }

  async exportJson () {
    try {
      if (this.exportingJson) {
        return
      }
      this.exportingJson = true

      const modelJson = await this.versionModule.versionExportJson({
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })

      const filename = `${this.currentLandscape.name} (${this.currentVersion.name}).json`
      const blob = new Blob([JSON.stringify(modelJson)], { type: 'application/json' })
      FileSaver.saveAs(blob, filename)

      analytics.shareExportJson.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkShortId: this.shortId,
        versionLatest: this.latestVersion
      })

      const contactPath = `contact?requestType=support&name=${this.userModule.user?.name}&email=${this.userModule.user?.email}`
      const contactLink = env.IS_PRODUCTION ? `https://icepanel.io/${contactPath}` : `https://icepanel.dev/${contactPath}`

      this.alertModule.pushAlert({
        color: 'success',
        duration: -1,
        message: `Export successful! <a href="${contactLink}" target="_blank">Leave feedback</a>.`
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'An error occured during export, please contact support'
      })
      throw err
    } finally {
      this.exportingJson = false
    }
  }

  async exportCsvObjects () {
    try {
      if (this.exportingCsvObjects) {
        return
      }
      this.exportingCsvObjects = true

      const objectsCsv = await this.modelModule.objectsExportCsv({
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })

      const filename = `${this.currentLandscape.name} objects (${this.currentVersion.name}).csv`
      const blob = new Blob([objectsCsv], { type: 'application/csv' })
      FileSaver.saveAs(blob, filename)

      analytics.shareExportCsvObjects.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkShortId: this.shortId,
        versionLatest: this.latestVersion
      })

      const contactPath = `contact?requestType=support&name=${this.userModule.user?.name}&email=${this.userModule.user?.email}`
      const contactLink = env.IS_PRODUCTION ? `https://icepanel.io/${contactPath}` : `https://icepanel.dev/${contactPath}`

      this.alertModule.pushAlert({
        color: 'success',
        duration: -1,
        message: `Export successful! <a href="${contactLink}" target="_blank">Leave feedback</a>.`
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'An error occured during export, please contact support'
      })
      throw err
    } finally {
      this.exportingCsvObjects = false
    }
  }

  async exportCsvConnections () {
    try {
      if (this.exportingCsvConnections) {
        return
      }
      this.exportingCsvConnections = true

      const connectionsCsv = await this.modelModule.connectionsExportCsv({
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })

      const filename = `${this.currentLandscape.name} connections (${this.currentVersion.name}).csv`
      const blob = new Blob([connectionsCsv], { type: 'application/csv' })
      FileSaver.saveAs(blob, filename)

      analytics.shareExportCsvConnections.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkShortId: this.shortId,
        versionLatest: this.latestVersion
      })

      const contactPath = `contact?requestType=support&name=${this.userModule.user?.name}&email=${this.userModule.user?.email}`
      const contactLink = env.IS_PRODUCTION ? `https://icepanel.io/${contactPath}` : `https://icepanel.dev/${contactPath}`

      this.alertModule.pushAlert({
        color: 'success',
        duration: -1,
        message: `Export successful! <a href="${contactLink}" target="_blank">Leave feedback</a>.`
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'An error occured during export, please contact support'
      })
      throw err
    } finally {
      this.exportingCsvConnections = false
    }
  }
}
